import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import twwitpng from '../Images/twitter.png'
import discordpng from '../Images/discord-high.png'
import instapng from '../Images/insta-high.png'
import eteschanpng from '../Images/ethscan.png'

const FooterNFT = () => {
  return (
    <footer className='d-flex flex-column justify-content-center mb-4'>
      <div className='d-flex justify-content-center '>
        <div className="d-flex align-items-center justify-content-between">
          <ul className="d-flex gap-4 flex-wrap align-items-center mb-0 p-0">
            <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white`} href="/">Utilities</a></li>
            <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white `} href="">Explore Collection</a></li>
            <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white `} href="">join Us</a></li>
          </ul>
        </div>
      </div><BrowserView>
        <div className={`d-flex justify-content-center align-items-center `}>

          <div className={`d-flex ${isMobile ? 'justify-content-center pt-3 gap-3' : 'justify-content-between'} align-items-center wii flex-wrap`}>
            <div className='d-flex align-items-center'>
              <p className='text-white m-0'>© 2023 Decentra Dragon, All right reserved.</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex gap-4 flex-wrap align-items-center mb-0 p-0">
                <li className="list-group-item"><a className={`nav-link btn  p-2 d-flex align-items-center `} href=""><img src={discordpng} alt="" width={"23px"} /></a></li>
                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white`} href="/"><img src={instapng} alt="" width={"23px"} /></a></li>
                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white `} href=""><img src={twwitpng} alt="" width={"23px"} /></a></li>
                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white `} href=""><img src={eteschanpng} alt="" width={"23px"} /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <div className={`d-flex justify-content-center align-items-center `}>
          <div className={`d-flex ${isMobile ? 'justify-content-center pt-3 gap-3' : 'justify-content-between'} align-items-center wii flex-wrap`}>

            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex gap-4 flex-wrap align-items-center mb-0 p-0">
                <li className="list-group-item"><a className={`nav-link btn  p-2 d-flex align-items-center `} href=""><img src={discordpng} alt="" width={"23px"} /></a></li>
                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white`} href="/"><img src={instapng} alt="" width={"23px"} /></a></li>
                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white `} href=""><img src={twwitpng} alt="" width={"23px"} /></a></li>
                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white `} href=""><img src={eteschanpng} alt="" width={"23px"} /></a></li>
              </ul>
            </div>
            <div className='d-flex align-items-center'>
              <p className='text-white m-0'>© 2023 Decentra Dragon, All right reserved.</p>
            </div>
          </div>
        </div>

      </MobileView>
    </footer>
  );
};

export default FooterNFT;
