import whiteinsta from '../Images/white-insta.png'
import topicon from '../Images/top-icon.png'
import twwitpng from '../Images/twitter.png'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import { useLocation } from "react-router-dom";
import SwipeableTopDrawer from "./bergur";

const HeaderNFT = () => {

    let location = useLocation();

    return (
        <header >
            <BrowserView>
                <div className="d-flex justify-content-center">
                    <div className="total-info1">
                        <div className="d-flex align-items-center justify-content-between">
                            <ul className="d-flex gap-4 flex-wrap align-items-center mb-0 p-0">
                                <li className="list-group-item"><a className={`nav-link btn  p-2 d-flex align-items-center `} href=""><img className="topicon-img" src={topicon} alt="" width={"44px"} /></a></li>
                                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white ${location.pathname === "/" ? "" : ""}`} href="/">Utilities</a></li>
                                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white ${location.pathname === "/ExploreCollection" ? "" : ""}`} href="">Explore Collection</a></li>
                                <li className="list-group-item"><a className={`nav-link btn p-2 pt-1 pb-1 text-white ${location.pathname === "/JoinUs" ? "" : ""}`} href="">join Us</a></li>
                            </ul>


                            <div className="d-flex">
                                <ul className="d-flex gap-2 flex-wrap align-items-center mb-0">
                                    <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 d-flex align-items-center `} href=""><img src={whiteinsta} alt="" width={"27px"} /></a></li>
                                    <li className="list-group-item"><a className={`nav-link btn bg-btn p-2 d-flex align-items-center `} href=""><img src={twwitpng} alt="" width={"27px"} /></a></li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className="d-flex justify-content-center head-m">
                    <div className="total-info1">
                        <div className="d-flex justify-content-between align-items-center">
                            <a className={`nav-link btn  p-2 d-flex align-items-center `} href=""><img src={topicon} alt="" width={"44px"} /></a>
                            {/* <h6 className="d-flex align-items-center"><strong className="title-size d-flex align-items-center pt-2"><a href="/" className="text-dark text-decoration-none d-flex align-items-center ">FUKaff</a></strong></h6> */}
                            <SwipeableTopDrawer></SwipeableTopDrawer>
                        </div>
                    </div>
                </div>
            </MobileView>

        </header>
    );
};

export default HeaderNFT;
