import React, { useState } from 'react';
import { SwipeableDrawer, IconButton, List, ListItem, ListItemText, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import istapng from '../Images/white-insta.png'
import twitter from '../Images/twitter.png'
import { useLocation } from 'react-router-dom';
import "../App.css"

const SwipeableTopDrawer = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (openState: any) => (event: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(openState);
  };
  let location = useLocation();

  return (
    <div className=''>
      <IconButton className="icon-burger" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="top  "
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className="top-title w-100 ms-2 pe-3">

            <div>
              {/*<a className={`nav-link btn bg-btn p-2 d-flex align-items-center `} href=""><img src={istapng} alt="" width={"27px"}  /></a>*/}
              <p className="header-top-text">Decentra Dragon</p>
            </div>
            <div>
              <button className='menu-btn pt-0 mt-0' onClick={toggleDrawer(false)}>
                <CloseIcon />
              </button>

            </div>




          </div>

          <a href="/Information"><button className={`menu-btn btn text-start text-white ${location.pathname === "/" ? "button-bergur" : ""}`}>Utilities</button></a>
          <a href=""><button className={`menu-btn btn text-start text-white ${location.pathname === "/ExploreCollection" ? "button-bergur" : ""}`}>Explore Collection</button></a>
          <a href=""><button className={`menu-btn btn text-start text-white${location.pathname === "/JoinUs" ? "button-bergur" : ""}`}>Join Us</button></a>



          <div className='d-flex justify-content-center me-2'>
            {/*<button className='menu-btn text-center  btn button-bergur'><InstagramIcon></InstagramIcon></button>*/}
            <button className='menu-btn text-center  btn button-bergur'><img src={istapng} width={'24px'} /></button>
            <button className='menu-btn text-center  btn button-bergur'><img src={twitter} width={'24px'} /></button>

          </div>


        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default SwipeableTopDrawer;