import '../App.css';
import imagecard1 from "../Images/img1-card.png"
import imagecard2 from "../Images/img2-card.png"
import imagecard3 from "../Images/img3-card.png"
import ldragron from "../Images/left-dragon.png"
import rdragon from "../Images/right-dragon.png"
import topdragon from "../Images/top-dragon-icon.png"
import star from "../Images/star.png"
import HeaderNFT from "../Component/Header";
import FooterNFT from "../Component/Footer";
import { isBrowser, isMobile, BrowserView, MobileView } from 'react-device-detect'

const HomePage = () => {


    return (
        <>


            <div className="all">
                <HeaderNFT></HeaderNFT>

                <div className="div-top">
                    <div className="container">
                        <img className={`${isMobile ? "top-dragon-m" : "top-dragon"}`} src={topdragon} />

                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className={`${isMobile ? "pt-3 wi-m" : "wi"} d-flex flex-column justify-content-center `}>
                            {/* <BrowserView>
                                <img className="star " src={star}/>
                            </BrowserView> */}
                            <div>
                                <p className={`main_title ${isMobile ? "main_title-m" : "ps-5 ms-5 me-5 pe-5"}  `}>Welcome
                                    to <br /> Decentra Dragon</p>
                            </div>
                            <BrowserView>
                            <p className={`secondtext ${isMobile ? "secondtext-m" : ""}`}> you want to be rich? You better don’t buy this Fu**ing dragons,
                                This
                                project is managed by drunk and mindless people get the Fu*k out of here!
                                </p>
                            </BrowserView>
                            <MobileView>
                            <p className={`secondtext ${isMobile ? "secondtext-m change-font-2" : ""}`}> you want to be rich? You better don’t buy this <br /> Fu**ing dragons,
                                This
                                project is managed by <br /> drunk and mindless people get the Fu*k out <br /> of here!
                                </p>
                            </MobileView>
                            
                        </div>
                    </div>

                    <div className="first-buttons">

                        <button className='parallelogram'>

                            <div className="btn-texts pt-3">

                                <p className="buy-text">Buy NOW!</p>
                            </div>
                        </button>
                        <button className="parallelogram-white">
                            <p className="btn-texts pt-3 text-white">Join Us</p>
                        </button>
                    </div>
                    <div className='w-100 pt-2 dragon-h'>
                        <div className="d-flex justify-content-between align-items-end">
                            <img className={` ${isMobile ? "left-dragon-m" : "left-dragon"}`} src={ldragron} />
                            <img className={`${isMobile ? "right-dragon-m" : "right-dragon"}`} src={rdragon} />
                        </div>
                    </div>


                </div>

                <div className={`${isMobile ? "loop-slide-m" : "loop-slide"} scrolling-text`}>
                    <div className={`${isMobile ? "gap-50-m" : "gap-50"} `}>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                        <p className='d-flex ps-5'>Decentra draGON <p className='ps-4 pt-3'>*</p></p>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                <div className={`pb-3 ${isMobile ? "bottom-div-m" : ""}`}>
                    <p className={`middle-tittle text-shadow ${isMobile ? "middle-tittle-m " : ""}`}>Community Utilities</p>
                    <BrowserView>
                    <p className={`middle-desc ${isMobile ? "middle-desc-m change-font" : ""}`}>Explore the diverse benefits within
                        our ecosystem — from exclusive project
                        access to powerful <br />trading signals and a community-driven online shop.
                    </p>
                    </BrowserView>
                    <MobileView>
                    <p className={`middle-desc ${isMobile ? "middle-desc-m change-font" : ""}`}>Explore the diverse benefits within
                        our <br /> ecosystem — from exclusive project
                        access <br />to powerful trading signals and a <br />community-driven online shop.
                    </p>
                    </MobileView>
                </div>
                </div>


                <div className="image-container d-flex justify-content-center mb-5 pb-5 mt-5">
                    <div className='wii d-flex justify-content-center flex-wrap gap-2'>
                        <div className={`card-1 c1 ${isMobile ? "mb-3 card1-m" : ""}`}>
                            <p className="card-title p-3">Alpha Group Crypto Signals</p>
                            <div className="card-header ps-3 pe-3 ">
                                Holders leverage the Alpha Group for crypto and airdrop signals, strategic insights to
                                enhance
                                their investment journey within the collection.
                            </div>
                            <div className="card-image">
                                <img className={` img-1 mb-1 text-ez ${isMobile ? "top-m" : ""}`} src={imagecard1}
                                    width={"100%"} />
                            </div>
                        </div>

                        <div className={`card-2 c2 ${isMobile ? "mb-3" : "ms-3 me-3"}`}>
                            <p className="card-title p-3">Alpha Group Crypto Signals</p>
                            <div className="card-header ps-3 pe-3">
                                Holders shape the future as they vote on the online shop's theme, with profits
                                reinvested into the collection, fostering a community-driven economy.
                            </div>
                            <div className="card-image">
                                <img className="img-2" src={imagecard2} width={"100%"} />
                            </div>
                        </div>
                        <div className={`card-3 c3 ${isMobile ? "mb-3" : ""}`}>
                            <p className=" ctext p-3 ">Dragon Owners Whitelisting</p>
                            <div className="card-header ps-3 pe-3 text-dark ">
                                Dragon owners enjoy an exclusive perk, getting whitelisted for upcoming team projects,
                                unlocking privileged access to exciting opportunities.
                            </div>
                            <div className="card-image">
                                <img className="img-3 " src={imagecard3} width={"100%"} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-center '>
                    <div className={` ${isMobile ? "bg-m" : "bg-d"} `}></div>
                </div>

                <div className="pt-5 mt-3">
                    <div className='d-flex justify-content-center'>
                        <div className={`pb-3 ${isMobile ? "bottom-div-m" : "bottom-div"}`}>
                            <p className={`middle-tittle text-shadow ${isMobile ? "middle-tittle-m" : "pt-1"}`}>Join Our Community</p>
                            <BrowserView>
                            <p className={`middle-desc2 ${isMobile ? "middle-desc-m change-font" : "pt-1"}`}>Explore the intersection of art
                                and blockchain with Decentra Dragon! <br/>  Join us for exclusive drops, community vibes, and
                                cutting-edge <br/>digital creativity. Follow now for the latest updates and <br/> announcements!
                            </p>
                            </BrowserView>
                            <MobileView>
                            <p className={`middle-desc2 ${isMobile ? "middle-desc-m change-font" : "pt-1"}`}>Explore the intersection of art
                                and blockchain with Decentra Dragon! Join us <br/> for exclusive drops, community vibes, and <br/>
                                cutting-edge digital creativity. Follow now for the latest updates and <br/> announcements!
                            </p>
                            </MobileView>
                        </div>
                    </div>

                    <div className={`first-buttons ${isMobile ? "mb-5" : "mb-5 pb-5"}`}>
                        <button className="parallelogram">
                            <div className="btn-texts pt-3">
                                <p className="buy-text" ><small>Join Discord</small></p>
                            </div>

                        </button>
                        <button className="parallelogram-white2">
                            <p className="btn-texts text-white" style={{ paddingTop: "20px" }}><small >Follow Twitter</small></p>
                        </button>
                    </div>



                    {/*<div >*/}
                    {/*    {isMobile ? (*/}
                    {/*        <div className={"allbox001-mob"}>*/}
                    {/*            <div style={{ textAlign: 'center' }}>*/}
                    {/*                <p className="stagestext-black-mob">Stages of Rarity: NFT</p>*/}
                    {/*                <div>*/}
                    {/*                    <p className="stagestext-black-mob" style={{ display: 'inline' }}>Collection</p>*/}
                    {/*                    <p className="stagestext-ylw-mob" style={{ display: 'inline' }}> Journey</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}


                    {/*        </div>*/}
                    {/*    ) : (*/}
                    {/*        <div className="allbox001">*/}
                    {/*            <p className="stagestext-black">Stages of Rarity: NFT Collection</p>*/}
                    {/*            <p className="stagestext-ylw">Journey</p>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}



                    <div className={`d-flex justify-content-center mb-3 ${isMobile ? "" : "mt-5 pt-5"}`}>
                        <hr className='hr-1 text-white' />
                    </div>

                </div>
                <FooterNFT></FooterNFT>


            </div>
        </>
    );
};

export default HomePage;