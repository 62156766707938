import React, { Fragment } from 'react';
import logo from './logo.svg';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import './App.css';

function App() {
  return (

    <>
      <BrowserRouter>
        <Routes>

          {/* <Route path='/Information' element={<Information />} /> */}
          <Route path='/' element={<HomePage />} />
          <Route
            path="*"
            element={<Navigate to="/Error404" replace />}
          />


        </Routes>
      </BrowserRouter>
    </>



  );
}
export default App;

